import styled from '@emotion/styled';

const Styles = styled.div({
  '.accordion': {
    h3: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
});

export default Styles;

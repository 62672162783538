import styled from '@emotion/styled';

import rhythm from '../../../../utils/rhythm';

const Styles = styled.section({
  paddingBottom: `${rhythm(1)} !important`,

  '.col': {
    marginBottom: rhythm(3 / 2),
  },
});

export default Styles;
